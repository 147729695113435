import React, { useEffect } from "react";
import { graphql, PageProps, navigate, useStaticQuery } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import clsx from "clsx";
import Layout from "../components/layout";
import SearchBox from "../components/contentElements/searchBox";
import H1 from "../components/htmlElements/h1";
import getMenu from "../lib/getMenu";
import getApiData from "../lib/getApiData";
import { parseLocation, getProjectName } from "../lib/functions";

export default function IndexRoute(props: PageProps) {
  const params: any = parseLocation(props);
  const projectName = getProjectName(true);

  /**
   * Redirect legacy details pages to the new ones
   */
  useEffect(() => {
    (async () => {
      if (params && params.id !== "") {
        try {
          const response = await getApiData({
            endpoint: `lookup/${params.id.replace("%3a", ":")}`,
            method: "get",
          });

          if (response?.status === 200) {
            navigate(`/details/${response.data}`);
          }
        } catch (error) {
          // Send error message to slack?
          console.log("🚀 ~ error", error);
        }
      }
    })();
  });

  const {
    irisHomepage,
    oasisHomepage,
    irisHomepageSidebar,
    oasisHomepageSidebar,
    irisSearchBox,
    oasisSearchBox,
  } = useStaticQuery(graphql`
    query {
      irisHomepage: markdownRemark(
        frontmatter: { pageId: { eq: "IRIS-homepage" } }
      ) {
        frontmatter {
          title
        }
        html
      }

      oasisHomepage: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-homepage" } }
      ) {
        frontmatter {
          title
        }
        html
      }

      irisHomepageSidebar: markdownRemark(
        frontmatter: { pageId: { eq: "IRIS-homepage-sidebar-content" } }
      ) {
        frontmatter {
          title
        }
        html
      }

      oasisHomepageSidebar: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-homepage-sidebar-content" } }
      ) {
        frontmatter {
          title
        }
        html
      }

      irisSearchBox: markdownRemark(
        frontmatter: { pageId: { eq: "IRIS-searchBox" } }
      ) {
        frontmatter {
          title
        }
        html
      }

      oasisSearchBox: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-searchBox" } }
      ) {
        frontmatter {
          title
        }
        html
      }
    }
  `);

  let homepageContent = "";
  let searchBoxTitle = "";
  let searchBoxContent = "";
  let sidebarContent = "";

  if (irisHomepage && projectName === "IRIS" && irisHomepage.html) {
    homepageContent = irisHomepage.html;
  }

  if (oasisHomepage && projectName === "OASIS" && oasisHomepage.html) {
    homepageContent = oasisHomepage.html;
  }

  homepageContent = homepageContent.replace(
    "<h2>",
    `<h2 class="text-2xl font-bold font-heading mt-0 pb-5">`
  );

  if (
    irisHomepageSidebar &&
    projectName === "IRIS" &&
    irisHomepageSidebar.frontmatter.title
  ) {
    searchBoxTitle = irisHomepageSidebar.frontmatter.title;
  }

  if (
    oasisHomepageSidebar &&
    projectName === "OASIS" &&
    oasisHomepageSidebar.frontmatter.title
  ) {
    searchBoxTitle = oasisHomepageSidebar.frontmatter.title;
  }

  if (irisSearchBox && projectName === "IRIS" && irisSearchBox.html) {
    searchBoxContent = irisSearchBox.html;
  }

  if (oasisSearchBox && projectName === "OASIS" && oasisSearchBox.html) {
    searchBoxContent = oasisSearchBox.html;
  }

  if (irisSearchBox && projectName === "IRIS" && irisSearchBox.html) {
    sidebarContent = irisSearchBox.html;
  }

  if (oasisSearchBox && projectName === "OASIS" && oasisSearchBox.html) {
    sidebarContent = oasisSearchBox.html;
  }

  sidebarContent = sidebarContent.replace(
    "<h2>",
    `<h2 class="text-2xl font-bold font-heading mt-0 pb-5">`
  );

  /**
   * Get the `informationMenu`
   */
  let informationMenu = "";

  try {
    if (projectName === "IRIS") {
      informationMenu = getMenu("informationPages").map((item: any) => {
        return (
          <li key={uuidv4()}>
            <a href={item.link}>{item.label}</a>
          </li>
        );
      });
    }
  } catch (error) {
    console.log("🚀 ~ IndexRoute ~ error:", error);
  }

  const bgClass = projectName === "OASIS" ? "bg-gray-light" : "bg-white";

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1
            innerContent={`Welcome to ${projectName}`}
            additionalClasses="pb-5"
          />
          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: homepageContent,
            }}
          />
          {informationMenu ? (
            <ul className="list-disc list-inside">{informationMenu}</ul>
          ) : null}
        </div>

        <div className="col-span-12 md:col-span-5 mb-5 homepage-search-box">
          <SearchBox
            parent="homepage"
            searchBoxTitle={searchBoxTitle}
            searchBoxContent={searchBoxContent}
          />

          <div
            className={clsx(
              bgClass,
              "rounded-lg shadow mx-auto sm:overflow-hidden  top-5 mt-5"
            )}
          >
            <div className="p-5">
              <div className="block pb-5">
                Submission and upload of new material
              </div>
              <a
                href="/submit/new/"
                className="primaryBackgroundColor hover:primaryBackgroundColor-hover text-white no-underline py-2 px-4 rounded text-center shadow-md mt-5"
              >
                Submit
              </a>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-7">
          {/* <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sidebarContent,
            }}
          /> */}
        </div>

        <div className="col-span-12 md:col-span-5">
          {projectName === "IRIS" ? (
            <>
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <div className="place-items-center">
                    <a
                      href="https://oasis-database.org/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/images/oasis-logo.png"
                        alt="Oasis"
                        className="scale-75 md:scale-50 lg:scale-50"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-span-12 border-t border-gray-2 mt-5 pt-5 text-center place-items-center">
                <img
                  style={{
                    height: `50px`,
                    display: `inline-block`,
                    marginRight: 10,
                  }}
                  alt="ESRC Logo"
                  src="/images/ESRC-LOGO.gif"
                />
                <img
                  style={{
                    height: `50px`,
                    display: `inline-block`,
                    marginRight: 10,
                  }}
                  alt="British Academy Logo"
                  src="/images/british-academy-logo.jpg"
                />
                <img
                  style={{ height: `50px`, display: `inline-block` }}
                  alt="Wiley Logo"
                  src="https://www.wiley.com/medias/Wiley-Logo-White.png?context=bWFzdGVyfGltYWdlc3w3NDUzfGltYWdlL3BuZ3xzeXMtbWFzdGVyL2ltYWdlcy9oMTYvaDUwLzg4ODM2Nzk3MjM1NTAvV2lsZXkgTG9nbyBXaGl0ZS5wbmd8Y2IxYmY3M2IyNzE0NTk3NzM1ZjEzYTk5YTQ5YjUzNzE0ZTMzYTcxYmViMjBmMGUwNGU4YjEwZTYwMTYwOGU0Zg"
                />
                <div style={{ padding: `10px` }}>
                  <img
                    style={{
                      height: `50px`,
                      display: `inline-block`,
                      marginRight: 10,
                    }}
                    alt="John Benjamins Logo"
                    src="/images/JB_logo.jpg"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 border-t border-gray-2 mt-5 pt-5 text-center place-items-center">
                Funded by:
                <br />
                <a
                  href="https://www.york.ac.uk/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: `inline-block` }}
                >
                  <img
                    src="https://www.york.ac.uk/static/stable/img/logo.svg"
                    alt="University of York logo"
                    style={{ width: `200px` }}
                  />
                </a>
                <br />
                <a
                  href="https://erasmus-plus.ec.europa.eu/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: `inline-block` }}
                >
                  <img
                    alt="Erasmus+ EU programme for education, training, youth and sport"
                    src="/images/oasis-sponsors/erasmusplus-logo.png"
                    style={{ width: `250px` }}
                  />
                </a>
                <br />
                <a
                  href="https://european-union.europa.eu/live-work-study/funding-grants-subsidies_en"
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: `inline-block` }}
                >
                  <img
                    alt="Funded by EU logo"
                    src="/images/oasis-sponsors/EN_co_fundedvertical_RGB_POS.png"
                    style={{ width: `100px` }}
                  />
                </a>
                <br />
                <a
                  href="https://www.realitea.info/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: `inline-block` }}
                >
                  <img
                    alt="The ReaLiTea project logo"
                    src="/images/oasis-sponsors/ReaLiTea_Logo_CMYK.png"
                    style={{ width: `200px` }}
                  />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
